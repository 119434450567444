import type { HttpTransportConfig } from "viem";
import { blast, blastSepolia } from "viem/chains";
import { Config, cookieStorage, createConfig, createStorage, fallback, http } from "wagmi";
import { IS_TESTNET, QUICKNODE_KEY, WALLET_CONNECT_PROJECT_ID } from "@looksrare/config";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { metaMaskWallet, rainbowWallet, coinbaseWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";
import { injected } from "wagmi/connectors";

const transportConfig: HttpTransportConfig = {
  batch: {
    batchSize: 100,
    wait: 500,
  },
  retryCount: 3,
  retryDelay: 1_000,
  timeout: 2_000,
};
const blastTransport = {
  [blast.id]: fallback(
    [
      http("https://rpc.blast.io"),
      http("https://blastl2-mainnet.public.blastapi.io"),
      http("https://blast.blockpi.network/v1/rpc/public"),
      http("https://rpc.ankr.com/blast"),
    ],
    {
      ...transportConfig,
      rank: {
        interval: 30_000,
        sampleCount: 3,
        timeout: 1000,
        weights: {
          latency: 0.1,
          stability: 0.9,
        },
      },
    }
  ),
};
const baseWalletConfig = {
  appName: "YOLO Games",
  projectId: WALLET_CONNECT_PROJECT_ID,
};

export const walletConfig = getDefaultConfig({
  ssr: true,
  chains: [IS_TESTNET ? blastSepolia : blast],
  transports: IS_TESTNET
    ? {
        [blastSepolia.id]: http(
          `https://icy-convincing-emerald.blast-sepolia.quiknode.pro/${QUICKNODE_KEY}/`,
          transportConfig
        ),
      }
    : blastTransport,
  wallets: [
    {
      groupName: "Popular",
      wallets: [metaMaskWallet, rainbowWallet, coinbaseWallet, walletConnectWallet],
    },
  ],
  storage: createStorage({
    storage: cookieStorage,
  }),
  ...baseWalletConfig,
});

export const getInjectedConnector = (win: Window) =>
  injected({
    target() {
      return {
        id: "windowProvider",
        name: "Window Provider",
        provider: win.ethereum,
      };
    },
  });

export const getpwaWalletConfig = (win: Window): Config =>
  createConfig({
    ssr: true,
    chains: [blast],
    transports: blastTransport,
    connectors: [getInjectedConnector(win)],
  });
