import { Box, HStack, StackProps, useDisclosure } from "@chakra-ui/react";
import { Button, CheckmarkFilledIcon, Modal, ModalCloseButton, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { ChainId } from "@looksrare/config";
import { gradients } from "@looksrare/chakra-theme";
import { useAccount } from "wagmi";
import { MoDContract, MoDRoundFragment } from "@looksrare/yolo-games-gql-typegen";
import { ClaimButton } from "../../../components/ClaimButton/ClaimButton";
import { getMoDContractsByChainId } from "../../../utils/getMoDContractsByChainId";
import { useMoDUnclaimedEntries } from "../../../network/graphql/hooks";
import { getTotalUnclaimedAmount } from "../../../components/ClaimButton/utils/getTotalUnclaimedAmount";
import { RoundEntry } from "../../../components/RoundEntry/RoundEntry";
import { RoundEntryTimer } from "../../../components/RoundEntry/components/RoundEntryTimer";

interface MoDMobileMenuProps extends StackProps {
  chainId: ChainId;
  contract: MoDContract;
  nextRound?: MoDRoundFragment;
  onEntry?: () => void;
}

export const MoDMobileMenu = ({ chainId, contract, nextRound, onEntry, ...props }: MoDMobileMenuProps) => {
  const { t } = useTranslation();
  const entryModalDisclosure = useDisclosure();

  const { address } = useAccount();
  const availableContracts = getMoDContractsByChainId(chainId);

  const { data: unclaimedEntries } = useMoDUnclaimedEntries(address!, availableContracts!, {
    enabled: !!address && !!availableContracts,
    refetchInterval: 7_000,
  });

  const totalUnclaimedAmount = unclaimedEntries ? getTotalUnclaimedAmount(unclaimedEntries) : 0n;
  const hasUnclaimedEntries = totalUnclaimedAmount > 0n;
  const hasEntered = address && !!nextRound?.entries?.[0];
  const nextRoundLockedAt = nextRound ? new Date(nextRound.lockedAt).getTime() : Date.now();
  const isNextRoundLocked = nextRoundLockedAt <= Date.now();

  const handleOnEntry = () => {
    entryModalDisclosure.onClose();
    onEntry?.();
  };

  return (
    <>
      <Modal
        isOpen={entryModalDisclosure.isOpen}
        onClose={entryModalDisclosure.onClose}
        modalContentProps={{ position: "relative", overflowY: "auto" }}
      >
        <ModalCloseButton onClick={entryModalDisclosure.onClose} position="absolute" top={3} right={3} zIndex={1} />
        {!!nextRound && (
          <RoundEntry chainId={chainId} contract={contract} round={nextRound} onEntry={handleOnEntry} flexShrink={0} />
        )}
      </Modal>

      <HStack
        px={2}
        py={3}
        borderTop="1px solid"
        borderColor="border-01"
        bgGradient={gradients["ui-gradient-v-01"]}
        {...props}
      >
        {hasEntered ? (
          <Button
            onClick={entryModalDisclosure.onOpen}
            rightIcon={<CheckmarkFilledIcon boxSize={5} />}
            variant="outline"
            colorScheme="secondary"
            flex={1}
          >
            {t("Entered Next")}
          </Button>
        ) : (
          <Button onClick={entryModalDisclosure.onOpen} flex={1} isDisabled={isNextRoundLocked}>
            <HStack justifyContent={hasUnclaimedEntries ? "center" : "space-between"} width="100%">
              <Text color="inherit">{t("Enter Next")}</Text>
              {!hasUnclaimedEntries && <RoundEntryTimer roundLockedAt={nextRoundLockedAt} />}
            </HStack>
          </Button>
        )}

        {hasUnclaimedEntries && <ClaimButton chainId={chainId} isCompact flex={1} />}

        <Box width={{ base: "48px", sm: "64px" }} />
      </HStack>
    </>
  );
};
