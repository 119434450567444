import { ReactNode, useEffect, useState } from "react";
import { Config } from "wagmi";
import { useIsBlastApp } from "@looksrare/utils";
import { getpwaWalletConfig, walletConfig } from "@/config";

type Args = {
  wagmiConfig: Config;
};

export const PwaAutoConnect = ({ children }: { children: (args: Args) => ReactNode }) => {
  const [wagmiConfig, setWagmiConfig] = useState<Config>(walletConfig);
  const isBlastApp = useIsBlastApp();

  useEffect(() => {
    if (isBlastApp) {
      setWagmiConfig(getpwaWalletConfig(window));
      window.ethereum?.request({ method: "eth_requestAccounts" });
    }
  }, [setWagmiConfig, isBlastApp]);

  return children({ wagmiConfig });
};
