import { useEffect, useState } from "react";

interface CustomWindow extends Window {
  ethereum?: {
    isBlastApp?: true;
    request: (...args: any[]) => void;
  };
}

const isBlastApp = () => {
  if (typeof window === "undefined") {
    return false;
  }

  // Set by the Blast Mobile app
  const customWindow = window as CustomWindow;
  return customWindow.ethereum?.isBlastApp === true;
};

export const useIsBlastApp = () => {
  const [isUsingBlastApp, setIsUsingBlastapp] = useState(isBlastApp());

  useEffect(() => {
    setIsUsingBlastapp(isBlastApp());
  }, [setIsUsingBlastapp]);

  return isUsingBlastApp;
};
