import { Box, HStack, IconButton, Stack, Switch } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ChainId } from "@looksrare/config";
import { HelpIcon, IconLinkButton, Popover, RoundCountdown, Text } from "@looksrare/uikit";
import { useCountdown, useLocalStorageSnooze } from "@looksrare/utils";
import { MoDAssetPair, MoDRoundFragment } from "@looksrare/yolo-games-gql-typegen";
import { AssetSwitcher } from "../../../components/AssetSwitcher/AssetSwitcher";
import { MOON_OR_DOOM_DOCS_PAGE } from "../../../config/constants";
import { useMoDStore } from "../../../stores/modStore";
import { useMoDConfig } from "../../../config";

interface MoDTopBarProps {
  chainId: ChainId;
  assetPair: MoDAssetPair;
  currentRound?: MoDRoundFragment;
}

export const MoDTopBar = ({ chainId, assetPair, currentRound }: MoDTopBarProps) => {
  const { t } = useTranslation();
  const { isSnoozed: isShineSnoozed, handleSnoozeIndefinitely } = useLocalStorageSnooze({
    baseKey: "moon-or-doom-assetswitcher-shine",
  });

  const { enabledAssetPairs } = useMoDConfig();
  const { shouldSmoothData, setShouldSmoothData } = useMoDStore();
  const { isSnoozed: isSmoothExplanationSnoozed, handleSnoozeIndefinitely: handleSnoozeSmoothExplanation } =
    useLocalStorageSnooze({
      baseKey: "moon-or-doom-smooth-data-explanation",
    });

  const roundClosedAt = currentRound?.closedAt ? new Date(currentRound.closedAt).getTime() : Date.now();
  const duration = useCountdown(roundClosedAt);

  return (
    <Stack direction={{ base: "column", md: "row" }} spacing={{ base: 2, sm: 4 }} justifyContent="space-between">
      <Box layerStyle={isShineSnoozed ? undefined : "animatedShineInfinite"}>
        <AssetSwitcher
          chainId={chainId}
          assetPairs={enabledAssetPairs}
          selectedPair={assetPair}
          onClickAssetPair={handleSnoozeIndefinitely}
        />
      </Box>

      <HStack spacing={4} justifyContent={{ base: "space-between", md: "start" }}>
        <HStack justifyContent="space-between">
          <HStack spacing={1}>
            <Text textStyle="body">{t("mod::Smooth data")}</Text>

            <Popover
              label={
                <Text textStyle="detail" color="text-inverse">
                  {t("mod::This view has a delay of 1-2 secs but provides a buttery smooth viewing experience.")}
                </Text>
              }
              isOpen={isSmoothExplanationSnoozed === false || undefined}
              onClose={handleSnoozeSmoothExplanation}
            >
              <IconButton
                icon={<HelpIcon boxSize={5} color="text-03" />}
                variant="ghost"
                size="xs"
                aria-label={`${t("mod::Round explanation")}?`}
              />
            </Popover>
          </HStack>

          <Switch isChecked={shouldSmoothData} onChange={() => setShouldSmoothData(!shouldSmoothData)} size="sm" />
        </HStack>

        <HStack spacing={2}>
          <IconLinkButton
            href={MOON_OR_DOOM_DOCS_PAGE}
            icon={<HelpIcon boxSize={5} />}
            variant="outline"
            colorScheme="secondary"
            aria-label={t("Learn More")}
            size="sm"
            isExternal
          />
          <RoundCountdown cutoffTimeMs={roundClosedAt} duration={duration} height="40px" px={{ base: 1, sm: 2 }} />A
        </HStack>
      </HStack>
    </Stack>
  );
};
