import { gql } from "graphql-request";
import { type Address } from "viem";
import { type BigIntish, graphql, getAuthToken, multiplyWeiByNumber } from "@looksrare/utils";
import { nonLoggableEvents } from "../config";
import { getPrizePerPlayerConsideringFees, getPtbContractNameFromNetwork, getRoundMeta } from "../utils";
import { getPrizePerPlayer } from "../hooks";
import type { PtbContractName, PtbSupportedNetwork } from "../types";
import {
  PtbRoundsSort,
  type PaginatedExtendedPtbRounds,
  type PtbRoundsFilter,
  type PtbCaveReturn,
  type OffsetPaginationInput,
  type ExtendedPtbRound,
  type PtbGraphQlLog,
  type RoundLogsReturn,
  type PtbUnclaimedEntry,
  type PtbRound,
  type PtbCave,
  type CaveInfoReturn,
  PtbUser,
} from "./types";
import {
  ptbCaveFragment,
  ptbCaveRoundFragment,
  ptbCaveRoundPlayerFragment,
  ptbPokeFragment,
  ptbUserFragment,
} from "./fragments";

interface GetCaveArgs {
  contract: PtbContractName;
  caveOnChainId: BigIntish;
  roundOnChainId?: BigIntish;
  requestHeaders?: HeadersInit;
}

export const getCave = async ({
  contract,
  caveOnChainId,
  roundOnChainId,
  requestHeaders,
}: GetCaveArgs): Promise<PtbCaveReturn> => {
  const query = gql`
    query GetCave($onChainId: Int!, $roundOnChainId: BigNumber, $contract: PtbContract) {
      ptbCave(onChainId: $onChainId, contract: $contract) {
        ...PtbCaveFragment
        round(onChainId: $roundOnChainId) {
          ...PtbCaveRoundFragment
          players {
            ...PtbCaveRoundPlayerFragment
            poke {
              ...PtbPokeFragment
            }
            user {
              ...PtbUserFragment
            }
          }
        }
      }
    }
    ${ptbCaveFragment}
    ${ptbCaveRoundFragment}
    ${ptbCaveRoundPlayerFragment}
    ${ptbPokeFragment}
    ${ptbUserFragment}
  `;

  const res = await graphql<{ ptbCave: PtbCaveReturn }>({
    query,
    params: { onChainId: Number(caveOnChainId), roundOnChainId, contract },
    requestHeaders,
  });
  return res.ptbCave;
};

interface GetActiveCavesRes {
  ptbCaves: PtbCaveReturn[];
}

export const getActiveCaves = async (
  contract: PtbContractName,
  requestHeaders?: HeadersInit
): Promise<PtbCaveReturn[]> => {
  const query = gql`
    query GetActiveCaves($contract: PtbContract) {
      ptbCaves(contract: $contract) {
        ...PtbCaveFragment
        round {
          ...PtbCaveRoundFragment
          players {
            ...PtbCaveRoundPlayerFragment
            poke {
              ...PtbPokeFragment
            }
            user {
              ...PtbUserFragment
            }
          }
        }
      }
    }
    ${ptbCaveFragment}
    ${ptbCaveRoundFragment}
    ${ptbCaveRoundPlayerFragment}
    ${ptbPokeFragment}
    ${ptbUserFragment}
  `;
  const res: GetActiveCavesRes = await graphql({
    params: { contract },
    query,
    requestHeaders,
  });
  return res.ptbCaves;
};

export const getCaveIds = async (
  network: PtbSupportedNetwork,
  requestHeaders?: HeadersInit
): Promise<{ onChainId: string; name: string }[]> => {
  const query = gql`
    query GetCaveIds($contract: PtbContract) {
      ptbCaves(contract: $contract) {
        onChainId
        name
      }
    }
  `;
  const res: { ptbCaves: { onChainId: string; name: string }[] } = await graphql({
    query,
    params: { contract: getPtbContractNameFromNetwork(network) },
    requestHeaders,
  });
  return res.ptbCaves.map(({ onChainId, name }) => ({ onChainId, name }));
};

interface GetRoundsArgs {
  filter: PtbRoundsFilter;
  pagination?: OffsetPaginationInput;
  sort?: PtbRoundsSort;
  requestHeaders?: HeadersInit;
  contract?: PtbContractName;
}

export const getRounds = async ({
  filter,
  pagination,
  sort = PtbRoundsSort.CREATED_AT_DESC,
  requestHeaders,
  contract = "PTB_V1",
}: GetRoundsArgs): Promise<PaginatedExtendedPtbRounds> => {
  const query = gql`
    query GetRounds(
      $filter: PtbRoundsFilterInput!
      $pagination: OffsetPaginationInput
      $sort: PtbRoundsSort
      $contract: PtbContract
    ) {
      ptbRounds(filter: $filter, pagination: $pagination, sort: $sort, contract: $contract) {
        total
        rounds {
          ...PtbCaveRoundFragment
          cave {
            onChainId
            isActive
            name
            contract
            enterAmount
            playersPerRound
            currency
          }
          players {
            ...PtbCaveRoundPlayerFragment
            poke {
              ...PtbPokeFragment
            }
            user {
              ...PtbUserFragment
            }
          }
        }
      }
    }
    ${ptbCaveRoundFragment}
    ${ptbCaveRoundPlayerFragment}
    ${ptbPokeFragment}
    ${ptbUserFragment}
  `;

  const res = await graphql<{ ptbRounds: PaginatedExtendedPtbRounds }>({
    query,
    params: { filter, pagination, sort, contract },
    requestHeaders,
  });
  return res.ptbRounds;
};

interface ActiveRoundArgs {
  caveOnChainId: BigIntish;
  contract: PtbContractName;
  requestHeaders?: HeadersInit;
}

/**
 * The "Active" round is the next joinable round
 */
export const getActiveRoundOnChainId = async ({
  caveOnChainId,
  contract,
  requestHeaders,
}: ActiveRoundArgs): Promise<BigIntish | null> => {
  const query = gql`
    query GetActiveRound($onChainId: Int!, $contract: PtbContract) {
      ptbCave(onChainId: $onChainId, contract: $contract) {
        nextRoundWithSlots {
          onChainId
        }
      }
    }
  `;

  const res = await graphql<{
    ptbCave: {
      nextRoundWithSlots: { onChainId: BigIntish };
    };
  }>({
    query,
    params: { onChainId: Number(caveOnChainId), contract },
    requestHeaders,
  });
  return res.ptbCave.nextRoundWithSlots.onChainId || null;
};

/**
 * The "Current" round is the round that is currently being played. If there is no round being played, then return the "next joinable" round (active round)
 */
export const getCurrentRoundOnChainId = async ({ caveOnChainId, contract }: ActiveRoundArgs) => {
  const query = gql`
    query GetCurrentRound($onChainId: Int!, $contract: PtbContract) {
      ptbCave(onChainId: $onChainId, contract: $contract) {
        round {
          onChainId
        }
      }
    }
  `;

  const res = await graphql<{ ptbCave: { round: { onChainId: number } } }>({
    query,
    params: { onChainId: Number(caveOnChainId), contract },
  });

  return res.ptbCave.round.onChainId || null;
};

interface GetPlayerUnclaimedRoundsArgs {
  filter: PtbRoundsFilter;
  requestHeaders?: HeadersInit;
}

export const getPlayerUnclaimedRounds = async ({ filter, requestHeaders }: GetPlayerUnclaimedRoundsArgs) => {
  const query = gql`
    query GetPlayerUnclaimedRounds($filter: PtbRoundsFilterInput!) {
      ptbRounds(filter: $filter) {
        players {
          user {
            ptbUnclaimedRefunds {
              cave {
                enterAmount
              }
              round {
                refunded
              }
            }
          }
        }
      }
    }
  `;

  const res = await graphql<{ ptbRounds: ExtendedPtbRound[] }>({
    query,
    params: { filter },
    requestHeaders,
  });
  return res.ptbRounds;
};

export const pokeTheBear = async (
  playerAddress: Address,
  caveOnChainId: BigIntish,
  roundOnChainId: BigIntish,
  network: PtbSupportedNetwork,
  requestHeaders?: HeadersInit
): Promise<boolean> => {
  const query = gql`
    mutation PokeTheBear($caveOnChainId: Int!, $roundOnChainId: BigNumber!, $contract: PtbContract) {
      ptbPoke(caveOnChainId: $caveOnChainId, roundOnChainId: $roundOnChainId, contract: $contract) {
        success
      }
    }
  `;
  const authCookie = getAuthToken(playerAddress);
  const res: { success: boolean } = await graphql({
    query,
    params: { caveOnChainId: Number(caveOnChainId), roundOnChainId, contract: getPtbContractNameFromNetwork(network) },
    requestHeaders: { ...requestHeaders, Authorization: `Bearer ${authCookie}` },
  });
  return res.success;
};

export interface UserUnclaimedWinningsAndRefunds {
  ptbUnclaimedWinnings: PtbUnclaimedEntry[];
  ptbUnclaimedRefunds: PtbUnclaimedEntry[];
}
interface GetUnclaimedWinningsRes {
  user: UserUnclaimedWinningsAndRefunds;
}

export const getUnclaimedWinnings = async (address: Address, network: PtbSupportedNetwork) => {
  const query = gql`
    query GetUnclaimedWinnings($address: Address!, $contract: PtbContract) {
      user(address: $address) {
        ptbUnclaimedWinnings(contract: $contract) {
          cave {
            onChainId
            currency
            enterAmount
            protocolFeeBp
            playersPerRound
          }
          round {
            onChainId
          }
          entryIndex
        }
        ptbUnclaimedRefunds(contract: $contract) {
          cave {
            onChainId
            currency
            enterAmount
            protocolFeeBp
            playersPerRound
          }
          round {
            onChainId
          }
          entryIndex
        }
      }
    }
  `;

  const res: GetUnclaimedWinningsRes = await graphql({
    query,
    params: { address, contract: getPtbContractNameFromNetwork(network) },
  });

  return res.user;
};

export const getRoundLogs = async (
  caveOnChainId: BigIntish,
  roundOnChainId: BigIntish,
  contract: PtbContractName,
  requestHeaders?: HeadersInit
): Promise<RoundLogsReturn> => {
  const query = gql`
    query RoundLogs($onChainId: Int!, $roundOnChainId: BigNumber, $contract: PtbContract) {
      ptbCave(onChainId: $onChainId, contract: $contract) {
        onChainId
        enterAmount
        playersPerRound
        round(onChainId: $roundOnChainId) {
          onChainId
          logs {
            type
            timestamp
            user {
              ...PtbUserFragment
            }
          }
        }
      }
    }
    ${ptbUserFragment}
  `;
  const res: {
    ptbCave: {
      onChainId: BigIntish;
      enterAmount: BigIntish;
      playersPerRound: number;
      round: {
        onChainId: BigIntish;
        logs: PtbGraphQlLog[];
      } | null;
    };
  } = await graphql({
    query,
    params: {
      onChainId: Number(caveOnChainId),
      roundOnChainId: Number.isNaN(Number(roundOnChainId)) ? null : roundOnChainId,
      contract,
    },
    requestHeaders,
  });
  const logs = res.ptbCave?.round?.logs || [];
  return {
    caveOnChainId: res.ptbCave.onChainId,
    roundOnChainId: res.ptbCave.round?.onChainId || null,
    enterAmount: res.ptbCave.enterAmount,
    playersPerRound: res.ptbCave.playersPerRound,
    // Remove any logs that should not show up in the round logs
    logs: logs.filter((log) => !nonLoggableEvents.includes(log.type)),
  };
};

export const getRoundInfo = async (
  caveOnChainId: BigIntish,
  contract: PtbContractName,
  roundOnChainId?: BigIntish
): Promise<CaveInfoReturn> => {
  // Flatten result to make it easier to access
  const { round, ...restCave } = await getCave({ caveOnChainId, roundOnChainId, contract });
  const { players, ...restRound } = round || {};
  const totalPrizePool = multiplyWeiByNumber(restCave.enterAmount, restCave.playersPerRound);

  const ptbRound = round ? (restRound as PtbRound) : null;
  const prizePerPlayer = getPrizePerPlayer(restCave);
  const prizePerPlayerNet = getPrizePerPlayerConsideringFees({
    enterAmount: restCave.enterAmount,
    protocolFeeBp: restCave.protocolFeeBp,
    playersPerRound: restCave.playersPerRound,
  });

  return {
    cave: restCave as PtbCave,
    round: ptbRound,
    players: players || [],
    meta: {
      totalPrizePool,
      prizePerPlayer,
      prizePerPlayerNet,
      ...getRoundMeta(ptbRound?.status),
    },
  };
};

export const getPtbUser = async (playerAddress: Address): Promise<PtbUser> => {
  const query = gql`
    query PtbPlayer($address: Address!) {
      user(address: $address) {
        ...PtbUserFragment
      }
    }
    ${ptbUserFragment}
  `;
  const res: { user: PtbUser } = await graphql({
    query,
    params: { address: playerAddress },
  });
  return res.user;
};
