import { useTranslation } from "next-i18next";
import { IconButton, ModalBody, Stack } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import {
  HandshakeIcon,
  GradientBorder,
  ModalHeroHeader,
  ModalProps,
  Text,
  Button,
  TwitterIcon,
  Link,
  ExternalLink,
} from "@looksrare/uikit";
import { JwtScope, useSignatureRequired } from "@looksrare/utils";
import { ChainId, IS_TESTNET } from "@looksrare/config";
import { useRouter } from "next/router";
import { gradients } from "@/theme/colors";
import { CopyButton } from "@/components/Buttons";
import { useReferralCode } from "@/queries/user";
import { ConnectWalletButton } from "@/components/ConnectWalletButton";

interface ReferralProps {
  onClose: ModalProps["onClose"];
}

export const Referral = ({ onClose }: ReferralProps) => {
  const { t } = useTranslation();
  const { signInHandler, isAuthorized, isConnected } = useSignatureRequired({
    options: {
      jwtScope: JwtScope.LongLived,
    },
  });
  const { chain } = useAccount();
  const router = useRouter();
  const appChainId = IS_TESTNET ? ChainId.BLAST_SEPOLIA : ChainId.BLAST_MAINNET;
  const { data: referralCode } = useReferralCode();

  // router.asPath avoids getting locale in the url, but requires trimming  query params
  const [pathName] = router.asPath.split("?");
  const referralUrlWithoutCode = `${window.location.origin}${pathName}?code=`; // separated from code only to build UI
  const referralUrl = `${referralUrlWithoutCode}${referralCode}`;
  const tweetText = t("Join me on YOLO Games — Blast's hottest gaming venue:");

  return (
    <>
      <ModalHeroHeader
        showCloseButton
        icon={HandshakeIcon}
        title={t("Referrals")}
        label={t(
          "Refer a friend to earn 20% of the points they make. And if your friend refers another friend, you'll earn 5% of their points, too! Terms apply."
        )}
        onClose={onClose}
      />
      <ModalBody pt={0}>
        <GradientBorder bgGradient={gradients.rainbow} innerProps={{ bg: "ui-01" }}>
          <Stack spacing={2} flexGrow={1} wordBreak="break-all">
            <Text textStyle="detail" color="text-03">
              {t("Your Referral Link")}
            </Text>
            {(() => {
              if (!isConnected) {
                return (
                  <Text color="text-disabled" textStyle="display-body" bold>
                    {t("Connect to View")}
                  </Text>
                );
              }
              if (!isAuthorized) {
                return (
                  <Button onClick={signInHandler} isDisabled={chain?.id !== appChainId}>
                    {t("Sign Message in Wallet")}
                  </Button>
                );
              }
              return (
                <Text color="link-02" bold>
                  {referralUrlWithoutCode}
                  <Text as="span" color="link-01" bold>
                    {referralCode}
                  </Text>
                </Text>
              );
            })()}
          </Stack>

          {isConnected ? (
            isAuthorized && (
              <>
                <CopyButton isDisabled={!referralCode} value={referralUrl} aria-label="copy-referral" />
                <IconButton
                  colorScheme="secondary"
                  aria-label="share-invite-x"
                  isDisabled={!referralCode}
                  as={Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    referralUrl
                  )}&text=${encodeURIComponent(tweetText)}`}
                >
                  <TwitterIcon />
                </IconButton>
              </>
            )
          ) : (
            <ConnectWalletButton />
          )}
        </GradientBorder>
        <Button
          mt={8}
          as={ExternalLink}
          href="https://docs.yologames.io/rewards/referrals"
          isExternal
          width="full"
          colorScheme="secondary"
        >
          {t("Learn More")}
        </Button>
      </ModalBody>
    </>
  );
};
