import { type ReactNode } from "react";
import { useLocalStorageSnooze, useUpdateChainId } from "@looksrare/utils";
import { useAccount } from "wagmi";
import { useRouter } from "next/router";
import { STORAGE_NAMESPACE } from "@looksrare/config";
import { useHandleReferral } from "@/hooks/referral";
import { PageLoadProgress } from "./PageLoadProgress";
import { TermsOfServiceModal } from "./Modals/TermsOfServiceModal";

/**
 * Paths that require the user to accept the terms of service.
 */
const PROTECTED_PATHS = [
  "/yolo/blast",
  "/poke-the-bear/cave",
  "/moon-or-doom",
  "/yolo-limited/blast",
  "/flipper",
  "/quantum",
  "/laser-blast",
  "/dont-fall-in",
];

export const GlobalAppWrapper = ({ children }: { children: ReactNode }) => {
  // Required due to underlying utils using "useCurrentChainId"
  useUpdateChainId();
  useHandleReferral();

  // Required for TermsOfService approval when accessing specific "game" paths
  const router = useRouter();
  const { address } = useAccount();
  const { isSnoozed: isOnboardingSnoozed, handleSnoozeIndefinitely: snoozeOnboardingIndefinitely } =
    useLocalStorageSnooze({
      baseKey: `${STORAGE_NAMESPACE}_new_account_onboarding_1`,
    });
  return (
    <>
      {PROTECTED_PATHS.some((protectedPath) => router.asPath.includes(protectedPath)) && (
        <TermsOfServiceModal isOpen={!isOnboardingSnoozed && !!address} onClose={snoozeOnboardingIndefinitely} />
      )}
      <PageLoadProgress />
      {children}
    </>
  );
};
