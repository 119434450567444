import { Address } from "viem";
import { getStorageKey } from ".";

/**
 * LR Auth
 */
export const getAuthToken = (address: Address): string | null => localStorage.getItem(getStorageKey("auth", address));

export const setAuthToken = (address: Address, value: string): void => {
  localStorage.setItem(getStorageKey("auth", address), value);
};

export const removeAuthToken = (address: Address) => {
  localStorage.removeItem(getStorageKey("auth", address));
};
