import { Address, WalletClient } from "viem";
import { JwtScope } from "../types";
import { getAuthToken, setAuthToken } from "../cookies";
import { isJwtValid } from "./isJwtValid";
import { signAndLogin } from "./signAndLogin";

/**
 * Check if a valid JWT exists, request a login if not
 * @param signer Ethers signer
 * @param account User address
 * @param scope Optional scope for validity check
 */
export const signAndLoginIfJwtIsInvalid = async (walletClient: WalletClient, account: Address, scope?: JwtScope) => {
  const authCookie = getAuthToken(account);

  if (authCookie && isJwtValid(authCookie, scope)) {
    return authCookie;
  }

  const jwt = await signAndLogin(walletClient, account);
  if (!isJwtValid(jwt)) {
    throw Error("Cannot request a valid JWT");
  }

  setAuthToken(account, jwt);
  return jwt;
};
