import { HStack, IconButton, Spinner, Stack, useDisclosure } from "@chakra-ui/react";
import {
  AddFundsButton,
  Button,
  ConnectWalletButton,
  ErrorIcon,
  HelpIcon,
  Popover,
  RollingCounter,
  Text,
} from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useAccount, useSwitchChain } from "wagmi";
import { ChainId } from "@looksrare/config";
import {
  formatNumberToLocale,
  getPointsMultiplier,
  getRoundPercentElapsed,
  useLocalStorageSnooze,
} from "@looksrare/utils";
import { getNetworkConfig } from "@looksrare/uikit/constants";
import { getUnixTime } from "date-fns/getUnixTime";
import { EntryPosition } from "../../../types";
import { useMoDConfig } from "../../../config";
import { multiplierThresholds, pointsPresets } from "../../../config/gems";
import { RoundEntryButton } from "./RoundEntryButton";
import { RoundEntryWarningModal } from "./RoundEntryWarningModal";
import { RoundEntryTimer } from "./RoundEntryTimer";
import { RoundEntryPointsExplanation } from "./RoundEntryPointsExplanation";

interface RoundEntryFooterProps {
  chainId: ChainId;
  roundLockedAt: number;
  entryAmountFloat: number;
  entryPosition: EntryPosition;
  roundDuration: number;
  isBalanceSufficient: boolean;
  isAboveMinEntry: boolean;
  isEntryClosed: boolean;
  isBlocked?: boolean;
  onClickEnter: () => void;
}

export const RoundEntryFooter = ({
  chainId,
  roundLockedAt,
  entryAmountFloat,
  entryPosition,
  roundDuration,
  isBalanceSufficient,
  isAboveMinEntry,
  isEntryClosed,
  isBlocked,
  onClickEnter,
}: RoundEntryFooterProps) => {
  const { t } = useTranslation();
  const warningModalDisclosure = useDisclosure();
  const { points } = useMoDConfig();
  const { pointsLabel, PointsIcon, pointsAccentColor } = pointsPresets[points.preset];

  const { isSnoozed: isWarningModalSnoozed, handleSnoozeIndefinitely: snoozeWarningModalIndefinitely } =
    useLocalStorageSnooze({
      baseKey: "moon_or_doom_warning_modal",
    });

  const { switchChain } = useSwitchChain();
  const { isDisconnected, chain } = useAccount();

  const { icon: ChainIcon } = getNetworkConfig(chainId);
  const isCorrectChain = chainId === chain?.id;

  const currentSeconds = getUnixTime(new Date());
  const secondsElapsedAsPercent = getRoundPercentElapsed({
    roundCutoffTime: getUnixTime(roundLockedAt),
    currentSeconds,
    roundDuration,
  });

  const handleWarningModalAgree = (snoozeIndefinitely: boolean) => {
    if (snoozeIndefinitely) {
      snoozeWarningModalIndefinitely();
    }

    warningModalDisclosure.onClose();
    onClickEnter();
  };

  return (
    <>
      <Stack spacing={2} p={4}>
        {points.enabled && (
          <HStack justifyContent="space-between">
            <HStack spacing={1}>
              <Text textStyle="detail" color="text-03">
                {t("Enter now to earn")}
              </Text>

              <Popover label={<RoundEntryPointsExplanation />} renderInPortal>
                <IconButton
                  icon={<HelpIcon boxSize={3} color="text-03" />}
                  variant="ghost"
                  size="xxs"
                  aria-label={`${t("mod::Round entry points explanation")}`}
                />
              </Popover>
            </HStack>

            <HStack spacing={1}>
              <PointsIcon boxSize={5} />

              <HStack spacing={1}>
                <RollingCounter
                  formattedValue={formatNumberToLocale(entryAmountFloat * (points.baseEntryPoints["ETH"] ?? 0), 0)}
                  fontSize="0.875rem"
                  lineHeight="1.25rem"
                  textProps={{ textStyle: "detail" }}
                />

                <Text textStyle="detail" color={pointsAccentColor}>
                  × {getPointsMultiplier(secondsElapsedAsPercent, multiplierThresholds)}
                </Text>
              </HStack>

              <Text textStyle="detail" color={pointsAccentColor}>
                {t(pointsLabel)}
              </Text>
            </HStack>
          </HStack>
        )}

        {(() => {
          if (isBlocked) {
            return (
              <Button rightIcon={<ErrorIcon boxSize={5} />} variant="outline" colorScheme="red" isDisabled>
                {t("Wallet Blocked")}
              </Button>
            );
          }

          if (isDisconnected) {
            return <ConnectWalletButton connectButtonText={t("Connect Wallet")} />;
          }

          if (!isCorrectChain) {
            return (
              <Button onClick={() => switchChain?.({ chainId })} leftIcon={<ChainIcon boxSize={5} />} variant="solid">
                {t("Change Network")}
              </Button>
            );
          }

          if (isEntryClosed) {
            return (
              <Button leftIcon={<Spinner boxSize={5} />} isDisabled>
                {t("Entry Closed")}
              </Button>
            );
          }

          if (!isBalanceSufficient) {
            return (
              <AddFundsButton variant="solid" colorScheme="secondary">
                {t("Add Funds")}
              </AddFundsButton>
            );
          }

          return (
            <RoundEntryButton
              entryPosition={entryPosition}
              onClick={!isWarningModalSnoozed ? warningModalDisclosure.onOpen : onClickEnter}
              width="100%"
              rightElement={<RoundEntryTimer roundLockedAt={roundLockedAt} />}
              isDisabled={!isAboveMinEntry}
            />
          );
        })()}
      </Stack>

      <RoundEntryWarningModal
        isOpen={warningModalDisclosure.isOpen}
        onClose={warningModalDisclosure.onClose}
        onAgree={handleWarningModalAgree}
      />
    </>
  );
};
