import { Address } from "viem";
import { getAuthToken } from "@looksrare/utils";

export const getAuthHeader = (account?: Address) => {
  if (!account) {
    return undefined;
  }
  const authToken = getAuthToken(account);
  const authHeader = {
    Authorization: `Bearer ${authToken}`,
  };
  return authHeader;
};
