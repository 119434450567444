import { type FC, type PropsWithChildren, type ReactNode } from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { useIsBlastApp } from "@looksrare/utils";
import { WalletIcon, WarningFilledIcon } from "../Icons";
import { Button, type ButtonProps } from "./Button";

export interface ConnectWalletButtonProps extends ButtonProps {
  onClick?: ButtonProps["onClick"]; // Making onClick optional
  onPwaConnect?: () => void;
  connectButtonText?: string | ReactNode;
}

// @ptb-todo migrate and figure out translation
export const ConnectWalletButton: FC<PropsWithChildren<ConnectWalletButtonProps>> = ({
  children,
  onPwaConnect,
  connectButtonText,
  ...props
}) => {
  const isBlastApp = useIsBlastApp();
  return (
    <ConnectButton.Custom>
      {({ account, chain, openChainModal, openConnectModal, mounted }) => {
        if (!mounted || !account || !chain) {
          return (
            <Button
              data-id="connect-wallet-button"
              {...props}
              onClick={(e) => {
                if (isBlastApp && onPwaConnect) {
                  onPwaConnect();
                } else {
                  openConnectModal();
                }
                props.onClick?.(e);
              }}
            >
              {connectButtonText || "Connect"}
            </Button>
          );
        }

        if (chain.unsupported) {
          return (
            <Button
              data-id="connect-wallet-button-unsupported"
              {...props}
              onClick={(e) => {
                openChainModal();
                props.onClick?.(e);
              }}
            >
              {"Unsupported Network"}
            </Button>
          );
        }

        return <Button {...props}>{children}</Button>;
      }}
    </ConnectButton.Custom>
  );
};

export type ConnectWalletIconButtonProps = Omit<IconButtonProps, "onClick" | "disabled" | "aria-label">;

export const ConnectWalletIconButton: React.FC<ConnectWalletIconButtonProps> = (props) => (
  <ConnectButton.Custom>
    {({ account, chain, openChainModal, openConnectModal, mounted }) => {
      if (!mounted || !account || !chain) {
        return (
          <IconButton data-id="connect-wallet-button" aria-label="connect wallet" onClick={openConnectModal} {...props}>
            <WalletIcon />
          </IconButton>
        );
      }

      if (chain.unsupported) {
        return (
          <IconButton
            data-id="connect-wallet-button-unsupported"
            aria-label="unsupported network"
            onClick={openChainModal}
            colorScheme="yellow"
            {...props}
          >
            <WarningFilledIcon />
          </IconButton>
        );
      }

      return (
        <IconButton aria-label="connect wallet" {...props}>
          <WalletIcon />
        </IconButton>
      );
    }}
  </ConnectButton.Custom>
);
