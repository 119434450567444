import { useConnect } from "wagmi";
import { ConnectWalletButton as UIKitConnectWalletButton, ConnectWalletButtonProps } from "@looksrare/uikit";
import { getInjectedConnector } from "@/config";

export const ConnectWalletButton = (props: Omit<ConnectWalletButtonProps, "onPwaConnect">) => {
  const { connect } = useConnect();
  const handlePwaConnect = () => {
    if (typeof window !== "undefined") {
      connect({ connector: getInjectedConnector(window) });
    }
  };

  return <UIKitConnectWalletButton onPwaConnect={handlePwaConnect} {...props} />;
};
