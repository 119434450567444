import { useAccount } from "wagmi";
import {
  Button,
  ButtonProps,
  ConnectWalletButton,
  EthHalfIcon,
  GiftIcon,
  useThrowConfettiFromElement,
  useToast,
} from "@looksrare/uikit";
import { formatToSignificant } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { useDisclosure } from "@chakra-ui/react";
import { ChainId } from "@looksrare/config";
import { useCallback, useEffect, useRef } from "react";
import { useMoDUnclaimedEntries } from "../../network/graphql/hooks";
import { getMoDContractsByChainId } from "../../utils/getMoDContractsByChainId";
import { ClaimModal } from "./components/ClaimModal";
import { getTotalUnclaimedAmount } from "./utils/getTotalUnclaimedAmount";
import { ClaimSuccessModal } from "./components/ClaimSuccessModal";

interface ClaimButtonProps extends ButtonProps {
  chainId: ChainId;
  isCompact?: boolean;
}

export const ClaimButton = ({ chainId, isCompact = false, ...props }: ClaimButtonProps) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const claimModalDisclosure = useDisclosure();
  const claimSuccessModalDisclosure = useDisclosure();
  const { toast } = useToast();
  const throwConfetti = useThrowConfettiFromElement(buttonRef);

  const { address } = useAccount();
  const availableContracts = getMoDContractsByChainId(chainId);

  const { data: unclaimedEntries } = useMoDUnclaimedEntries(address!, availableContracts!, {
    enabled: !!address && !!availableContracts,
    refetchInterval: 7_000,
  });
  const totalUnclaimedAmount = unclaimedEntries ? getTotalUnclaimedAmount(unclaimedEntries) : 0n;

  useEffect(() => {
    if (totalUnclaimedAmount > 0n) {
      throwConfetti();
    }
  }, [throwConfetti, totalUnclaimedAmount]);

  const handleComplete = useCallback(() => {
    claimModalDisclosure.onClose();
    claimSuccessModalDisclosure.onOpen();

    toast({
      title: t("Claimed!"),
      description: `${t("Your winnings are now in your wallet")}.`,
      status: "success",
    });
  }, [claimModalDisclosure, claimSuccessModalDisclosure, t, toast]);

  return (
    <>
      {(() => {
        if (!address) {
          return <ConnectWalletButton connectButtonText={t("Check Winnings")} colorScheme="secondary" {...props} />;
        }

        if (totalUnclaimedAmount > 0n) {
          if (isCompact) {
            return (
              <Button
                ref={buttonRef}
                leftIcon={<GiftIcon boxSize={4} />}
                onClick={claimModalDisclosure.onOpen}
                {...props}
              ></Button>
            );
          }

          return (
            <Button
              ref={buttonRef}
              leftIcon={<GiftIcon boxSize={4} />}
              onClick={claimModalDisclosure.onOpen}
              {...props}
            >
              {isCompact ? (
                t("Claim")
              ) : (
                <>
                  {t("Claim {{amount}}", { amount: formatToSignificant(totalUnclaimedAmount) })}
                  <EthHalfIcon height={4} width={2} />
                </>
              )}
            </Button>
          );
        }

        return (
          <Button ref={buttonRef} variant="outline" colorScheme="secondary" isDisabled {...props}>
            {t("Nothing to Claim")}
          </Button>
        );
      })()}

      {!!unclaimedEntries && (
        <ClaimModal
          totalUnclaimedAmount={totalUnclaimedAmount}
          unclaimedEntries={unclaimedEntries}
          isOpen={claimModalDisclosure.isOpen}
          onClose={claimModalDisclosure.onClose}
          onComplete={handleComplete}
        />
      )}
      <ClaimSuccessModal isOpen={claimSuccessModalDisclosure.isOpen} onClose={claimSuccessModalDisclosure.onClose} />
    </>
  );
};
