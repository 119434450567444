import { Address } from "viem";
import jwt_decode from "jwt-decode";
import { getAuthToken } from "../cookies";
import { AuthPayload, JwtScope } from "../types";
import { isAddressEqual } from "../guards";
import { isJwtValid } from "./isJwtValid";

/**
 * Check if an account is authorized
 * @param account
 * @param scope Optional scope for validity check
 * @returns true if jwt is valid for the given scope, and the connected account matches the jwt address
 */
export const isAuthorized = (account?: Address | null, scope?: JwtScope) => {
  if (!account) {
    return false;
  }

  const authCookie = getAuthToken(account);

  if (!authCookie || !isJwtValid(authCookie, scope)) {
    return false;
  }

  const { address } = jwt_decode<AuthPayload>(authCookie);

  return isAddressEqual(address, account);
};
