import { useEffect, useRef } from "react";
import Options from "@widgetbot/crate/dist/types/options";
import Crate from "@widgetbot/crate/dist/api";
import { useIsBlastApp } from "../media";

export const useWidgetBotCrate = (props: Options) => {
  const crateRef = useRef<Crate>();
  const exists = useRef(false);
  const isOpenRef = useRef(false);
  const isBlastApp = useIsBlastApp();

  useEffect(() => {
    if (!exists.current && !isBlastApp) {
      exists.current = true;

      (async () => {
        const res = await import("@widgetbot/crate");
        const CrateClass = await res.cdn();

        crateRef.current = new CrateClass(props);

        crateRef.current.on("message", () => {
          if (!isOpenRef.current) {
            crateRef.current?.setOptions({
              ...props,
              css: "button::after { content: ''; position: absolute; top: 0; right: 0; width: 18px; height: 18px; border-radius: 50%; background: rgb(255, 42, 42); }",
            });
          }
        });

        crateRef.current.node.addEventListener("click", () => {
          isOpenRef.current = !isOpenRef.current;
          crateRef.current?.setOptions({
            ...props,
            css: "",
          });
        });
      })();
    }

    return () => {
      const widgets = document.querySelectorAll("widgetbot-crate");
      widgets.forEach((widget) => widget.remove());
    };
  }, [props, isBlastApp]);
};
